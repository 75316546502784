import ImgRanks from '../../assets/images/topten/top-rated-ranks.gif';
import styled from 'styled-components';
import Link from '../../components/Link';
import { mediaQueries } from '../../utils/mediaQueries';

export const Container = styled.div`
  padding: 100px 0 0;
  ${mediaQueries('md')} {
    padding: 0;
  }
`;

export const TopThreeContainer = styled.div``;

export const Podium = styled.div`
  img {
    display: block;
    margin: 0 auto;
  }
`;
export const TopThree = styled.div`
  position: relative;

  .prod {
    position: absolute;
    width: 227px;
    height: 227px;
  }

  .num1 {
    transform: translate(-50%, -50%);
    top: 18%;
    left: 50%;
  }

  .num2 {
    top: -4%;
    left: 13.5%;
  }

  .num3 {
    top: 4%;
    right: 13.5%;
  }
`;

export const Name = styled.div`
  color: #ffffff;
`;

export const ProdLink = styled(Link)`
  background: ${(props) => props.theme.relatedProduct.buttonBg};
  padding: 3px 0;
  color: #232323;
  width: 130px;
  display: block;
  margin: 0 auto;
  font-size: 12px;
  transition: all 0.3s;
  text-align: center;

  &:hover {
    background: ${(props) => props.theme.relatedProduct.buttonHoverBg};
    color: #232323;
    text-decoration: none;
  }
`;

export const TopThreeDetail = styled.div`
  max-width: 735px;
  margin: 30px auto;

  ${Name} {
    font-size: 18px;
    color: #c3c3c3;
    margin-bottom: 15px;
  }
`;

export const Rank = styled.div`
  height: 36.8px;
  width: 55px;
  background-image: url(${ImgRanks});
  margin-left: 20px;

  ${mediaQueries('md')} {
    margin-bottom: 10px;
  }
`;

export const Box = styled.div`
  background-color: #1a1a1a;
  & > div {
    ${getChild(0, 11)};
  }
`;

export const OtherImage = styled.div`
  position: absolute;
  bottom: 0;
  left: 80px;

  width: 105px;
  height: 105px;

  .gatsby-image-wrapper {
    margin: 0 auto;
    & > div {
      padding-bottom: 105px !important;
    }
  }

  picture {
    img {
      object-fit: contain !important;
    }
  }
`;

export const List = styled.div`
  max-width: 739px;
  margin: 50px auto;
  position: relative;
  left: 30px;
`;

export const ListItem = styled.div`
  position: relative;
  background: #1a1a1a;
  height: 80px;

  &:not(:last-child) {
    margin-bottom: 50px;
  }

  ${Name} {
    padding-top: 15px;
    padding-left: 120px;
    width: 70%;
  }

  ${ProdLink} {
    margin: 0;
  }

  ${getChild(111, 8)}
`;

function getChild(start = 0, time) {
  let str = '';
  for (let i = 1; i < time; i += 1) {
    str += `
    &:nth-child(${i}){
      ${Rank}{
        background-position-y: ${(i - 1) * -36.9 - start}px;
      }
    }
  `;
  }
  return str;
}
